<template>
  <div>
    <center>
      <div id="button_div">
        <button id="new_report_btn" type="button" class="btn btn-dark" @click.prevent="newReport">
          <i style="float: right; padding-top: 3px" class="fa fa-angle-double-right"></i>

          Advanced Report
        </button>
        <fieldset id="schedular" class="scheduler-border2">
          <legend class="scheduler-border2" style="color: #999999; text-align: left">
            Selected Filters
          </legend>
          <div style="display: flex; flex-wrap: wrap; padding: 5px">
            <div id="filtre_div" v-if="platform_filter != null">
              <p>Platform : {{ platform_filter }}</p>
            </div>
            <div id="filtre_div" v-if="publisher_country_filter != null">
              <p>Publisher country : {{ publisher_country_filter }}</p>
            </div>
            <div id="filtre_div" v-if="category_filter != null">
              <p>Category : {{ category_filter }}</p>
            </div>
            <div id="filtre_div" v-if="last_updated_filter != null">
              <p>Last updated : {{ last_updated_filter }}</p>
            </div>
            <div id="filtre_div" v-if="arpdau_filter != null">
              <p>Arpdau : {{ arpdau_filter }}</p>
            </div>
            <div id="filtre_div" v-if="rpd_filter != null">
              <p>Rpd : {{ rpd_filter }}</p>
            </div>
            <div id="filtre_div" v-if="growth_rate_filter != null">
              <p>Growth rate : {{ growth_rate_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d1_filter != null">
              <p>D1 : {{ d1_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d7_filter != null">
              <p>D7 : {{ d7_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d30_filter != null">
              <p>D30 : {{ d30_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d60_filter != null">
              <p>D60 : {{ d60_filter }}</p>
            </div>
            <div id="filtre_div" v-if="is_ua_active_filter != null">
              <p>Is Ua Active : {{ is_ua_active_filter }}</p>
            </div>
          </div>
        </fieldset>
      </div>
    </center>
    <center>
      <div id="table_div">
        <b-table id="new_data_table" bordered hover :fields="fields" :items="json_report_data" head-variant="light">
          <template slot="thead-top" slot-scope="{}">
            <b-tr>
              <b-th colspan="15"><span class="sr-only">Name and ID</span></b-th>
              <b-th colspan="4" style="text-align: center">Retention</b-th>
            </b-tr>
          </template>
          <template v-slot:head(index)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">By DLs</p>
          </template>

          <template v-slot:head(humanized_name)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">Publisher Name</p>
          </template>
          <template v-slot:head(average_users)="data">
            {{ data.label }}
          </template>
          <template v-slot:head(prior_average_users)="data">
            {{ data.label }}
          </template>
          <template v-slot:head(growth)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">in Users</p>
          </template>
          <template v-slot:head(growth_percent)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">in Users</p>
          </template>
          <template v-slot:head(revenue)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">Last Month</p>
          </template>
          <template v-slot:head(rpd)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">All Time, WW</p>
          </template>
          <template v-slot:head(arpdau)="data">
            {{ data.label }}
            <br />
            <p style="font-weight: lighter; font-size: 10px">Last Month, US</p>
          </template>

          <template v-slot:head(publisher_country)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(active_ua)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(last_updated)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(release_date)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(d1)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(d7)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(d30)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.label }}</p>
          </template>
          <template v-slot:head(d60)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.label }}</p>
          </template>

          <template v-slot:cell(index)="data">
            <center>
              {{ data.index + 1 }}
            </center>
          </template>

          <template #cell(icon_url)="data">
            <center>
              <b-img :src="data.item.icon_url" fluid style="width: 32px; height: 32px; border-radius: 1rem" />
            </center>
          </template>
          <template #cell(humanized_name)="data">
            <p class="text-info" style="font-size: 14px; margin-bottom: 0px; margin-top: 10px"
              @click="openStore(data.index, data.item.app_pkg)">
              {{ data.item.humanized_name }}
            </p>
            <p style="font-weight: lighter; margin-top: 0px" @click="getAppInfo(data.index)">
              {{ data.item.publisher_name }}
            </p>
          </template>
          <template #cell(average_users)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(prior_average_users)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(growth)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(growth_percent)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(revenue)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(rpd)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(arpdau)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(publisher_country)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(active_ua)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(last_updated)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(release_date)="data">
            <p style="text-align: right; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(d1)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(d7)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(d30)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.value }}</p>
          </template>
          <template #cell(d60)="data">
            <p style="text-align: center; margin-top: 10px">{{ data.value }}</p>
          </template>
        </b-table>
      </div>
    </center>
  </div>
</template>
<script>
import axios from "axios";
//import reportData from "./../data.json";
export default {
  title: "Megafortuna Market Analyzer - Basic Report",
  components: {},
  data() {
    return {
      fields: [
        { key: "index", label: "Rank" },
        { key: "icon_url", label: "", sortable: false, tdClass: "icon_cell" },
        { key: "humanized_name", label: "App Name", sortable: false },
        {
          key: "average_users",
          label: "Average Users",
          sortable: true,
          tdClass: "average_users",
        },
        {
          key: "prior_average_users",
          label: "Prior Avg Users",
          sortable: true,
          tdClass: "prior_average_users",
        },
        { key: "growth", label: "Growth", sortable: true },
        {
          key: "growth_percent",
          label: "Growth %",
          sortable: true,
          tdClass: "growth_percent_cell",
        },
        { key: "revenue", label: "Revenue", sortable: true },
        { key: "rpd", label: "RPD", sortable: true, tdClass: "rpd_class" },
        {
          key: "arpdau",
          label: "ARPDAU",
          sortable: true,
          thStyle: { width: "110px" },
        },
        { key: "publisher_country", label: "Publisher Country" },
        { key: "active_ua", label: "Active UA", sortable: false },
        { key: "last_updated", label: "Last Updated", sortable: false },
        { key: "release_date", label: "Release Date", sortable: false },
        { key: "d1", label: "D1", sortable: true },
        { key: "d7", label: "D7", sortable: true },
        { key: "d30", label: "D30", sortable: true },
        { key: "d60", label: "D60", sortable: true },
      ],
      json_report_data: null,
      report_id: this.$route.query.report_id,
      filters: null,
      category_filter: null,
      arpdau_filter: null,
      rpd_filter: null,
      platform_filter: null,
      publisher_country_filter: null,
      game_category_filter: null,
      last_updated_filter: null,
      growth_rate_filter: null,
      d1_filter: null,
      d7_filter: null,
      d30_filter: null,
      d60_filter: null,
      is_ua_active_filter: null,
    };
  },
  created() {
    localStorage.component = "Basic Report";
  },
  beforeMount() {
    axios
      .get(
        "https://pow.games:5000/getreport?report_id=" + this.report_id
      )
      .then((response) => {
        console.log(response);
        this.json_report_data = response.data.apps;
        this.filters = response.data.filters;
        this.platform_filter = this.filters.platform;
        this.publisher_country_filter = this.filters.publisher_country;
        this.last_updated_filter = this.filters.last_updated;
        this.growth_rate_filter = this.filters.growth_rate;
        this.d1_filter = this.filters.d1;
        this.d7_filter = this.filters.d7;
        this.d30_filter = this.filters.d30;
        this.d60_filter = this.filters.d60;
        this.is_ua_active_filter = this.filters.is_ua_active;
        if (this.filters.game_category != null) {
          this.category_filter = this.filters.game_category.join(", ");
        }
        if (this.filters.app_category != null) {
          this.category_filter = this.filters.app_category.join(", ");
        }
        if (this.filters.arpdau != null) {
          this.arpdau_filter = this.filters.arpdau.join(", ");
        }
        if (this.filters.rpd != null) {
          this.rpd_filter = this.filters.rpd.join(", ");
        }
        this.dataDetails();
      });

    console.log(this.report_id);
  },
  computed: {},
  methods: {
    dataDetails() {
      this.json_report_data = this.json_report_data.map((data) => {
        return {
          app_pkg: data.app_pkg,
          humanized_name: this.getAppName(data.humanized_name, data.name),
          publisher_name: data.publisher_name,
          icon_url: data.icon_url,
          average_users: data.average_users,
          prior_average_users: data.prior_average_users,
          growth: data.growth,
          growth_percent: "% " + data.growth_percent.toFixed(2),
          revenue: data.revenue,
          rpd: data.rpd,
          arpdau: data.arpdau,
          publisher_country: data.publisher_country,
          active_ua: data.active_ua,
          last_updated: data.last_updated,
          release_date: data.release_date,
          d1: this.fixedValue(data.d1),
          d7: this.fixedValue(data.d7),
          d30: this.fixedValue(data.d30),
          d60: this.fixedValue(data.d60),
        };
      });
    },
    fixedValue(data) {
      if (data == null) {
        return null;
      } else {
        return Number(data.toFixed(2));
      }
    },
    getAppName(humanized_name, name) {
      if (humanized_name == null) {
        return name;
      } else {
        return humanized_name;
      }
    },
    getAppInfo(items) {
      console.log("App İnfo " + items);
      var item = this.json_report_data[items];
      console.log(item);
    },
    openStore(index, app_id) {
      if (this.json_report_data[index].app_pkg.toString().includes(".")) {
        window.open(
          "https://play.google.com/store/apps/details?id=" + app_id,
          "_blank"
        );
      } else {
        window.open("https://apps.apple.com/US/app/id" + app_id, "_blank");
      }
    },
    newReport() {
      this.$router.push("/grid_report?report_id=" + this.report_id);
    },
  },
};
</script>
<style >
*:focus {
  outline: 0 !important;
}

#multiple_selects {
  border: 0.1px solid gray;
  border-radius: 1rem;
  height: 125px;
  align-items: center;
  padding: 25px;
  margin-bottom: 50px;
}

#new_data_table {
  width: 95%;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-style: hidden;
  /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #ddd;
  font-size: 12px;
}

#table_div {
  justify-content: center;
}

.card {
  width: 95%;
}

.card-header {
  text-align: left;
}

fieldset.scheduler-border {
  border: 1px solid #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  width: 95%;
  border-radius: 1rem;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.table thead tr th {
  vertical-align: middle;
}

.table thead tr {
  font-size: 12px;
}

.table tbody tr td {
  vertical-align: middle;
}

.icon_cell {
  width: 50px;
  min-width: 50px;
}

.growth_percent_cell {
  width: 90px;
  min-width: 70px;
}

.prior_average_users {
  width: 90px;
}

.average_users {
  width: 90px;
}

.rpd_class {
  width: 50px;
}

#new_report_btn {
  width: 180px;
  height: 40px;
  border-radius: 5rem;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 10px;
  float: right;
  outline: none;
  font-family: "NotoSans-Regular";
  padding: 0.375rem 0.75rem;
}

#button_div {
  width: 95%;
}
</style>