<template>
  <div>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand id="nav_brand" href="#" class="d-inline-block align-top">
        <img width="50" height="50" src="../assets/crown_crawler_icon.png" class="d-inline-block align-center"
          alt="Kitten" style="margin-right: 10px" />
        Megafortuna Market Analyzer
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-navbar-nav class="ml-auto">
        <b-nav-item id="logout_item" v-if="component == 'Filters'" @click="Logout"><i class="py-0 fa fa-sign-out"
            style="float: right; margin-left: 10px"></i>Logout</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      component: null,
    };
  },
  created() {
    setTimeout(() => {
      this.component = localStorage.component;
    }, 100);
  },
  methods: {
    Logout() {
      this.$confirm("Are you sure you want to log out?", "", "question")
        .then(() => {
          localStorage.crawlerLogin = false;
          this.$router.push("/login");
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
};
</script>
<style scoped>
#nav_brand {
  font-size: 18px;
  font-family: 'NotoSans-Regular';
}

.navbar-brand {
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
  font-size: 18px;
  height: auto;
  white-space: nowrap;
}

.navbar-nav li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.nav-item a {
  color: #ffffff;
  background-color: transparent;
  font-size: 16px;
}

.navbar a:hover {
  background-color: transparent;
}

.navbar {
  border-radius: 0px;
  padding: 1rem 1rem;
  margin-bottom: 10px;
}

#logout-item {
  font-family: 'NotoSans-Regular';
}
</style>