<template>
  <div class="login_div">
    <div class="signin">
      <center>
        <div class="signin-classic">
          <center>
            <div style="align-items: center">
              <img src="../assets/crown_crawler_icon.png" alt="" width="130px" height="130px" />
              <div style="padding-top: 20px; margin-bottom: 50px">
                <h1 id="login_title">MEGAFORTUNA MARKET ANALYZER</h1>
              </div>
            </div>
            <form class="form" style="align-items: center; height: 100%">
              <fieldset class="username">
                <input class="form-control" type="text" placeholder="username" v-model="value_username" />
              </fieldset>
              <fieldset class="password">
                <input class="form-control" type="password" placeholder="password" v-model="value_password" />
              </fieldset>
              <button class="btn btn-lg btn-block" type="submit" @click.prevent="Login">
                LOGIN
              </button>
              <p id="status_info" style="
                        color: #7a7a7a;
                        font-size: 14px;
                        margin-top: 5px;
                        visible: false;
                      ">
                {{ status }}
              </p>
            </form>
          </center>
        </div>
      </center>
    </div>
  </div>
</template>
<script>
export default {
  title: "MEGAFORTUNA MARKET ANALYZER",
  data() {
    return {
      username: "king",
      password: "burger",
      value_username: "",
      value_password: "",
      status: null,
    };
  },
  methods: {
    Login() {
      if (this.value_password != "" && this.value_username != "") {
        if (
          this.value_password == this.password &&
          this.value_username == this.username
        ) {
          localStorage.crawlerLogin = true;
          if (
            localStorage.pathname != undefined &&
            localStorage.search != undefined
          ) {
            this.$router.push(localStorage.pathname + localStorage.search);
            localStorage.removeItem("path");
            localStorage.removeItem("pathname");
            localStorage.removeItem("search");
          } else {
            this.$router.push("/filters");
          }
        } else if (
          this.value_password !== this.password ||
          this.value_username !== this.username
        ) {
          document.getElementById("status_info").style.visibility = "true";
          this.status = "Incorrect username or password!";
        }
      } else {
        document.getElementById("status_info").style.visibility = "true";
        this.status = "Please type your username and password!";
      }
    },
  },
};
</script>
<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

#login_title {
  font-family: "NotoSans-Regular";
}

.btn {
  display: block;
  color: hsl(0, 0, 100);
  text-decoration: none;
  margin: 20px 0;
  padding: 15px 15px;
  border-radius: 5px;
  position: relative;
}

.form fieldset {
  border: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
}

.form input {
  width: 80%;
  height: 48px;
  padding: 15px 40px 15px 15px;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  vertical-align: top;
  font-family: "NotoSans-Regular";
}

.form button {
  width: 80%;
  outline: none !important;
  border: none;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 20px;
  transition: background-color 0.5s;
  box-shadow: 0 3px 0px hsla(86, 21%, 44%, 0.2);
  font-family: "NotoSans-Regular";
  background: #4757d9;
}

.form button:hover {
  background: #6f7fff;
}

.signin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 35%;
  padding-top: 50px;
  padding-bottom: 50px;
  border: 0.1px solid #ccddff;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  box-sizing: border-box;
}

.signin-classic h1 {
  color: #343a40;
  text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
}

.signin-classic fieldset::after {
  content: "\f007";
  font-family: FontAwesome;
  position: absolute;
  right: 60px;
  top: 17px;
  z-index: 2;
  width: 20px;
  text-align: center;
}

.signin-classic fieldset.email::after {
  content: "\f0e0";
}

.signin-classic fieldset.password::after {
  content: "\f023";
}
</style>